<template>
  <div class="intro">
    <nav-bar show-back="1"></nav-bar>

    <div class="content">
      <div class="title">Aturan Event</div>
      <h4>1. Untuk membeli：</h4>
      <div class="item">
        1) Pilih produk yang anda mau di platform Wowsaa;<br/>
        2) Minta bantuan teman untuk bekerja sama membeli produk dengan harga khusus, Wowsaa  menjamin harga produk yang dijual jauh lebih murah daripada platform belanja online lainnya di indonesia seperti Tokopedia, Shopee, DLL.<br/>
        3) Jika anda berhasil mengundang 1 teman untuk bergabung dalam waktu 24 jam, Wowsaa akan memberikan anggota grup masing-masing 1 kode promo.<br/>
        4) Setelah mendapatkan kode promo, Anda dapat menyalin kode promo untuk masuk ke toko Wowsaa, masukkan kode promo saat melakukan pemesanan, dan harga produk akan diperbarui secara otomatis.<br/>
        5) Pilih metode pembayaran untuk menyelesaikan pembayaran, maka pembelian produk dapat berhasil.<br/>
        6) Setelah pembelian selesai, Wowsaa akan mengirimkan barang tepat waktu sesuai dengan informasi pemesanan, pastikan informasi pemesanan benar dan akurat.
      </div>
      <h4>2. Waktu pengiriman produk:</h4>
      <div class="item">
        1) Barang biasa:<br/>
         Setelah pembayaran selesai, maka produk akan dikirim secara otomatis. Untuk Top Up pulsa akan memakan waktu sekitar 1 menit hingga 5 menit.<br/>
        2) Produk terbatas khusus:<br/>
         Setelah pembayaran selesai, produk akan dikirim secara manual. Untuk Top Up pulsa akan memakan waktu sekitar 2 jam.
      </div>
      <h4>3. Batasan jumlah partisipan:</h4>
      <div class="item">
        1) Produk gratis:<br/>
        Pengguna yang sama hanya dapat mendapatkan barang gratis satu kali dengan cara bergabung dengan grup. Setelah melebihi jumlah tersebut, mereka tidak dapat bergabung dengan grup untuk membeli lagi.<br/>
        2) Produk lainnya:<br/>
        Pengguna yang sama dapat berpartisipasi bergabung dengan grup beberapa kali dan mendapatkan diskon untuk produk dengan harga yang sangat murah.<br/>
        Catatan: Pengguna yang sama mengacu pada pengguna dengan nomor ponsel atau email yang sama.
      </div>
      <h4>4. Aturan kode promo:</h4>
      <div class="item">
        1) Masa berlaku kode promo: 3 hari, berlaku hanya untuk 3 hari dimulai dari tanggal penerimaan;<br/>
        2) Silakan memesan di Wowsaa sesegera mungkin ketika kode promo hampir berakhir;<br/>
        3) Setelah tanggal kedaluwarsa, kode promo tidak valid lagi, dan produk yang dipilih tidak dapat dibeli dengan harga promosi. <br/>
        4) Satu kode promo hanya dapat berlaku untuk membeli 1 produk, jika anda membeli produk dalam jumlah banyak, maka diskon hanya berlaku untuk satu produk saja, dan produk lainnya tidak akan mendapatkan harga diskon.
      </div>
      <h4>5. Aturan anti-kecurangan untuk event:</h4>
      <div class="item">
        Jika pengguna yang berpartisipasi dalam event ini memiliki perilaku yang tidak pantas (curang, dll.), Wowsaa berhak untuk membatalkan kualifikasi event tanpa pemberitahuan sebelumnya.
      </div>
      <h4>6. Penafian acara:</h4>
      <div class="item">
        Jika karena kegagalan sistem atau force majeure lainnya, aktivitas tidak berjalan normal atau event gagal, Wowsaa berhak menyesuaikan aktivitas atau mengakhiri event lebih awal.
      </div>
      <h4>7. Hak untuk menafsirkan kegiatan ini adalah milik Wowsaa dalam ruang lingkup yang ditentukan oleh  hukum.</h4>
    </div>

    <div class="iphone-x"></div>
  </div>
</template>

<script>
  import NavBar from '../../components/navBar'

  export default {
    components: {
      NavBar
    },
    created() {
      window.track('wowsaa-groups', 'intro', 'expose')
    }
  }
</script>

<style lang="scss" scoped>
  .intro {
    padding-bottom: pxTo(16);

    @extend %page;
    background-color: $c11;
  }

  .content {
    margin: pxTo(16) pxTo(16) 0 pxTo(16);
    padding: pxTo(20);
    background-color: $c2;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: pxTo(6);

    .title {
      margin-bottom: pxTo(10);

      @extend %ff-rm;
      @include fs-l-w-c(16, 19, 400, $c1);
    }

    h4{
      margin: 5px 0;
    }

    .sub-title {
      margin-bottom: pxTo(10);

      @extend %ff-rm;
      @include fs-l-w-c(12, 14, 500, $c9);

      &.m-t {
        margin-top: pxTo(24-6);
      }
    }

    .item {
      margin-bottom: pxTo(12);
      margin-left: pxTo(10);

      @extend %ff-rr;
      @include fs-l-w-c(12, 18, 400, $c10);
    }
  }
</style>